import { useCookies } from '@vueuse/integrations/useCookies'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { maxBy, minBy } from 'lodash-es'
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import { h } from 'vue'
import { useTippy } from 'vue-tippy'

import { useAuthStore } from '@/store/auth.js'

import AddressesModal from '@/components/modals/AddressesModal.vue'
import AuthModal from '@/components/modals/AuthModal.vue'
import IframeModal from '@/components/modals/IframeModal.vue'
import MapModal from '@/components/modals/MapModal.vue'
import ShopQrModal from '@/components/modals/ShopQrModal.vue'
import UIAuthTippy from '@/components/ui/UIAuthTippy.vue'
import UIEsiaTippy from '@/components/ui/UIEsiaTippy.vue'
import useConfirm from '@/plugins/confirm.js'
import useDynamicModal from '@/plugins/useModalDynamic.js'
import useModalDynamic from '@/plugins/useModalDynamic.js'

export function numberWithCommas(number) {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

/**
 * @param {Date} date
 * @param {String} format
 * @return {String}
 */
export function getDateFormat(date, format = 'D MMMM YYYY') {
  return dayjs(date).locale('ru').format(format)
}

/**
 * @param {Date} starts_at
 * @param {Date} finishes_at
 * @return {Boolean}
 */
export function isBetween(starts_at, finishes_at) {
  const now = dayjs()
  return now.isAfter(dayjs(starts_at)) && now.isBefore(dayjs(finishes_at))
}

/**
 * @param {Number} count
 * @param {Array} words
 * @param {Boolean} extend
 * @return {String}
 */
export function plural(count, words, extend = false) {
  const cases = [2, 0, 1, 1, 1, 2]
  const word =
    words[
      count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
    ]
  return extend ? `${count} ${word}` : word
}
export function isAuthorized() {
  const authStore = useAuthStore()
  return !!authStore.user
}

/**
 * @param {String} lzString
 * @return DecompressedValue
 */
export function lzDecompress(lzString) {
  const val = encodeURI(lzString).replaceAll('%20', '+')
  return JSON.parse(decompressFromBase64(val))
}
/**
 * @param {any} value
 * @return {String} CompressedValue
 */
export function lzCompress(value) {
  return compressToBase64(JSON.stringify(value))
}

// Show auth modal
export function callAuthModal() {
  const modal = useModalDynamic({
    opened: true,
    component: AuthModal,
    compAttrs: {
      onConfirm: () => {
        console.log('log')
        modal.close()
      },
    },
  })
}

export async function callExternalModal(url) {
  const result = await useConfirm({
    title: 'Вы покидаете портал',
    caption:
      'Администрация ресурса не несет ответственности за данные, размещаемые на внешних ресурсах.',
  })

  if (result) {
    window.open(url, '_blank', 'noreferrer').focus()
  }
}

export function callAddressesModal(eventId, addresses) {
  const modal = useDynamicModal(AddressesModal, {
    modalAttrs: {
      onConfirm: () => {
        modal.close()
      },
    },
    componentAttrs: {
      eventId,
      addresses,
      onClose: () => {
        modal.close()
      },
    },
  })
  modal.open()
}

export function callMapModal(addresses, mapParams) {
  const modal = useDynamicModal({
    component: MapModal,
    modalAttrs: {
      onConfirm: () => {
        modal.close()
      },
    },
    compAttrs: {
      addresses,
      ...mapParams,
      onClose: () => {
        modal.close()
      },
    },
  })
  modal.open()
}

export function downloadFile(title, extension, link) {
  return (
    fetch(link)
      // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
      .then((resp) =>
        resp.status === 200
          ? resp.blob()
          : Promise.reject('something went wrong'),
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // the filename you want
        a.download = title + '.' + extension
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        console.log(e)
      })
  )
}

export function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0 Bytes'
  let k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
/**
 * @param {String} url
 */
export function openInNewTab(url) {
  window.open(url, '_blank').focus()
}

export function useIframeModal(url) {
  useModalDynamic({
    opened: true,
    component: IframeModal,
    compAttrs: {
      url: url,
      iframeClass: 'w-8/12 rounded-lg mx-auto bg-white h-full',
    },
  })
}

export function isMobileApp() {
  return useCookies().get('nsmapp')
}

export function isWebview() {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const standalone = navigator.standalone

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)

  return (
    (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
    (isIos && !standalone && !isSafari)
  )
}

export function openShopQrModal(caption = null, id = null) {
  useModalDynamic({
    opened: true,
    component: ShopQrModal,
    compAttrs: {
      id: id,
      caption:
        caption ??
        'Предъявите данный код представителю пункта выдачи для получения вашего заказа.',
    },
  })
}

export function calculcateCenterPoint(items = []) {
  const lt1 = minBy(items, (i) => i.coordinates.latitude)?.coordinates.latitude
  const lt2 = maxBy(items, (i) => i.coordinates.latitude)?.coordinates.latitude

  const ln1 = minBy(items, (i) => i.coordinates.longitude)?.coordinates
    .longitude
  const ln2 = maxBy(items, (i) => i.coordinates.longitude)?.coordinates
    .longitude

  const latMid = (lt1 + lt2) / 2
  const lonMid = (ln1 + ln2) / 2

  return { latitude: latMid, longitude: lonMid }
}

export function createTippyAuth(element, body) {
  if (!element && !element.target && !element.value) return
  const instance = useTippy(element.value ? element.value : element.target, {
    content: h(UIAuthTippy, {
      body: body,
      hide: () => instance.hide(),
    }),
    showOnCreate: true,
    trigger: 'manual',
    interactive: true,
    appendTo: () => document.body,
    onHidden() {
      instance?.destroy()
    },
  })
}

export function createTippyEsia(element, body) {
  if (!element && !element.target && !element.value) return
  const instance = useTippy(element.value ? element.value : element.target, {
    content: h(UIEsiaTippy, {
      body: body,
      hide: () => instance.hide(),
    }),
    showOnCreate: true,
    trigger: 'manual',
    interactive: true,
    appendTo: () => document.body,
    onHidden() {
      instance?.destroy()
    },
  })
}

export function isMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substring(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
